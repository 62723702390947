<template>
  <div>
    <form-wizard
      color="#3e74c7"
      :title="null"
      :subtitle="null"
      layout="vertical"
      finish-button-text="Létrehozás"
      next-button-text="Tovább"
      next-button-icon="fa fa-arrow-right"
      back-button-text="Vissza"
      class="wizard-vertical mb-3"
      @on-complete="formSubmitted"
    >

      <!-- account datails tab -->
      <tab-content title="Feladat információk">
        <b-row>
          <b-col
            cols="12"
            class="mb-2"
          >
            <h5 class="mb-0">
              Feladat adatai
            </h5>
            <small class="text-muted">
              Kérlek add meg az információkat
            </small>
          </b-col>
          <b-col md="12">
            <b-form-group
              label="Feladat típusa"
              label-for="work_type"
            >
              <b-form-select
                id="work_type"
                v-model="work_type"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="work_type_options"
              />
            </b-form-group>
          </b-col>
          <b-col md="12" v-show="work_type == 'Nem projekttel kapcsolatos'">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Feladat megnevezése"
                  label-for="project_name"
                >
                  <b-form-input
                    id="project_name"
                    v-model="project_name"
                    type="text"
                    placeholder="Munka megnevezése"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Feladatot kapja"
                  label-for="preparatoryLeader"
                >
                  <b-form-select
                    id="preparatoryLeader"
                    v-model="selectedPreparatoryLeader"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="preparatoryLeaderOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Prioritás"
                  label-for="priority"
                >
                  <b-form-select
                    id="priority"
                    v-model="priority"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="priorityOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Határidő"
                  label-for="enddate"
                >
                  <b-form-input
                    id="enddate"
                    v-model="endDate"
                    type="date" max="9999-12-31"
                    placeholder="Munka határideje"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Feladat leírása"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    placeholder="Bármi megjegyzés a feladattal kapcsolatosan"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    type="text"
                    placeholder="Email link"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Fájl feltöltése"
                  label-for="file_upload"
                >
                  <b-form-file
                    id="file_upload"
                    v-model="file_upload"
                    accept="application/pdf"
                    multiple
                    placeholder="Fájl feltöltése"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" v-show="work_type == 'Saját feladat'">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Feladat megnevezése"
                  label-for="project_name"
                >
                  <b-form-input
                    id="project_name"
                    v-model="project_name"
                    type="text"
                    placeholder="Munka megnevezése"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Prioritás"
                  label-for="priority"
                >
                  <b-form-select
                    id="priority"
                    v-model="priority"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="priorityOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Határidő"
                  label-for="enddate"
                >
                  <b-form-input
                    id="enddate"
                    v-model="endDate"
                    type="date" max="9999-12-31"
                    placeholder="Munka határideje"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Feladat leírása"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    placeholder="Bármi megjegyzés a feladattal kapcsolatosan"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" v-show="work_type == 'Projekthez kapcsolódik'">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Melyik céghez tartozik?"
                  label-for="company"
                >
                  <b-form-select
                    id="company"
                    v-model="company"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="companyOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Munka azonosító"
                  label-for="work_number"
                >
                  <b-form-input
                    v-model="work_number"
                    :limit="10"
                    :input-props="{class:'form-control'}"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Feladat megnevezése"
                  label-for="project_name"
                >
                  <b-form-input
                    id="project_name"
                    v-model="project_name"
                    type="text"
                    placeholder="Munka megnevezése"
                  />
                </b-form-group>
              </b-col>
              <!--<b-col md="6">
                <b-form-group
                  label="Melyik projekthez tartozik?"
                  label-for="project"
                >
                  <b-form-select
                    id="project"
                    v-model="project"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="projectOptions"
                  />
                </b-form-group>
              </b-col>-->
              <b-col md="6">
                <b-form-group
                  label="Megrendelő"
                  label-for="customer"
                >
                  <b-form-input
                    id="customer"
                    v-model="customer"
                    placeholder="Munka megrendelője"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Feladatot kapja"
                  label-for="preparatoryLeader"
                >
                  <b-form-select
                    id="preparatoryLeader"
                    v-model="selectedPreparatoryLeader"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="preparatoryLeaderOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Prioritás"
                  label-for="priority"
                >
                  <b-form-select
                    id="priority"
                    v-model="priority"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="priorityOptions"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Email"
                  label-for="email"
                >
                  <b-form-input
                    id="email"
                    v-model="email"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Határidő"
                  label-for="enddate"
                >
                  <b-form-input
                    id="enddate"
                    v-model="endDate"
                    type="date" max="9999-12-31"
                    placeholder="Munka határideje"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Munka leírása"
                  label-for="description"
                >
                  <b-form-textarea
                    id="description"
                    v-model="description"
                    placeholder="Bármi megjegyzés a projekttel kapcsolatosan"
                  />
                </b-form-group>
              </b-col>
              <b-col md="12">
                <b-form-group
                  label="Fájl feltöltése"
                  label-for="file_upload"
                >
                  <b-form-file
                    id="file_upload"
                    v-model="file_upload"
                    accept="application/pdf"
                    multiple
                    placeholder="Fájl feltöltése"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
          <b-col md="12" v-show="work_type == 'Megjegyzés'">
            <b-row>
              <b-col md="6">
                <b-form-group
                  label="Kivel kapcsolatos?"
                  label-for="target_id"
                >
                  <b-form-select
                    id="target_id"
                    v-model="target_id"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="employeeList"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Rövid leírás"
                  label-for="shortTitle"
                >
                  <b-form-input
                    id="shortTitle"
                    v-model="shortTitle"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Megjegyzés"
                  label-for="title"
                >
                  <b-form-input
                    id="title"
                    v-model="title"
                    type="text"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Összeg"
                  label-for="price"
                >
                  <b-form-input
                    id="price"
                    v-model="price"
                    type="number"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
              <b-col md="6">
                <b-form-group
                  label="Levonás dátuma"
                  label-for="selected_date"
                >
                  <b-form-input
                    id="selected_date"
                    v-model="selected_date"
                    type="month"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  />
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </tab-content>
    </form-wizard>

  </div>
</template>

<script>
import { VueAutosuggest } from 'vue-autosuggest'
import { FormWizard, TabContent } from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import { getAuthUsers } from '@/middleware/authLoader'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormSelect,
  BFormFile,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import axios from 'axios'

export default {
  components: {
    FormWizard,
    TabContent,
    BRow,
    BCol,
    BFormTextarea,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    // eslint-disable-next-line vue/no-unused-components
    VueAutosuggest,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  data() {
    return {
      getAuthUsers,
      priorityOptions: [
        { value: 'Alacsony', text: 'Alacsony' },
        { value: 'Közepes', text: 'Közepes' },
        { value: 'Fontos', text: 'Fontos' },
        { value: 'Azonnal', text: 'Azonnal' },
      ],
      preparatoryLeaderOptions: [],
      selectedPreparatoryLeader: null,
      priority: null,
      customer: null,
      file_upload: null,
      project_name: null,
      work_id: null,
      work_type: 'Nem projekttel kapcsolatos',
      description: null,
      email: null,
      startDate: new Date().toISOString().substr(0, 10),
      endDate: null,
      projectOptions: [],
      shortTitle: null,
      project: null,
      datasuggest: [],
      filteredOptions: [],
      work_number: null,
      employeeList: [],
      price: null,
      target_id: null,
      title: null,
      limit: 10,
      target_name: null,
      selected: null,
      company: null,
      work_type_options: [],
      companyOptions: [
        {
          text: 'Nem tartozik céghez',
          value: '',
        },
        {
          value: 'Monolit Épszer Kft.',
          text: 'Monolit Épszer Kft.',
        },
        {
          value: 'Monolit Profi Kft.',
          text: 'Monolit Profi Kft.',
        },
      ],
    }
  },
  mounted() {
    const userRole = JSON.parse(localStorage.getItem('user'))

    this.work_type_options.push(
      {
        text: 'Feladat',
        value: 'Nem projekttel kapcsolatos',
      },
    )

    if (userRole.role.includes('create_offer')) {
      this.work_type_options.push(
        {
          text: 'Ajánlat',
          value: 'Projekthez kapcsolódik',
        },
      )
    }

    if (userRole.role.includes('payments_create')) {
      this.work_type_options.push(
        {
          text: 'Fizetés',
          value: 'Megjegyzés',
        },
      )
    }

    this.work_type_options.push(
      {
        text: 'Saját feladat',
        value: 'Saját feladat',
      },
    )

    const urlParams = new URLSearchParams(window.location.search)
    const preLoad = urlParams.get('q')
    if (preLoad) {
      this.work_type = preLoad
    }

    (async () => {
      this.getAuthUsers('all').then(res => {
        res.forEach(user => {
          this.preparatoryLeaderOptions.push({
            value: user.id,
            text: user.name,
          })
        })
      })
    })()

    axios({
      method: 'get',
      url: 'employee/list/only_name_and_id',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      res.data.forEach(project => {
        this.employeeList.push({
          value: project.id,
          text: project.name,
        })
      })
    })

    axios({
      method: 'get',
      url: 'project/list/all',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.projectOptions.push({
        value: 0,
        text: 'Nem tartozik projekthez',
      })
      res.data.forEach(project => {
        this.projectOptions.push({
          value: project.id,
          text: project.name,
        })
      })
    })

    axios({
      method: 'get',
      url: 'project/task/work_id_list',
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    }).then(res => {
      this.datasuggest = res.data
    })
  },
  watch: {
    work_type() {
      this.priority = null
      this.project = null
      this.work_id = null
      this.description = null
      this.project_name = null
      this.customer = null
      this.shortTitle = null
      this.work_number = null
      this.selected = null
      this.company = null
      this.selectedPreparatoryLeader = null
      this.endDate = null
      this.email = null
      this.title = null
      this.target_name = null
      this.target_id = null
      this.price = null
    },
    priority() {
      if (this.priority === 'Azonnal') {
        this.endDate = new Date().toISOString().substr(0, 10)
      } else if (this.priority === 'Fontos') {
        this.endDate = new Date(Date.now() + 1 * 24 * 60 * 60 * 1000).toISOString().substr(0, 10)
      } else {
        this.endDate = null
      }
    },
    company() {
      axios({
        method: 'get',
        url: `project/task/generate_work_id/${this.company}`,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.work_number = res.data
      })
    },
  },
  methods: {
    generateNextWorkNumber() {
      if (this.company != null) {
        axios({
          method: 'get',
          url: `project/task/generate_work_id/${this.company}`,
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }).then(res => {
          this.work_number = res.data
        })
      } else {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Nincs kiválasztva hogy melyik céghez tartozik!',
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      }
    },
    onInputChange(text) {
      if (text === undefined) {
        this.filteredOptions = []
        this.filteredOptions.push({ data: this.datasuggest })
        return null
      }
      // SELECT datasuggest from datasuggest where datasuggest like '%' + text + '%'
      const filtered = this.datasuggest.filter(
        item => item.toLowerCase().indexOf(text.toLowerCase()) > -1,
      ).filter(item => item.length - 1 >= text.length)
      this.filteredOptions = [{
        data: filtered,
      }]
    },
    checkWorkNumber(work) {
      axios({
        method: 'post',
        url: 'project/task/check_work_number',
        data: {
          work_id: work.item,
        },
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }).then(res => {
        this.work_number = res.data.work_number
        this.company = res.data.company
        this.work_id = work.item
      })
    },
    generateID() {
      this.work_id = `#${Math.floor(Math.random() * 1000000000)}-`
      this.onInputChange(this.work_id)
    },
    formSubmitted() {
      if (this.work_type === 'Megjegyzés') {
        axios({
          method: 'post',
          url: 'private_comment/create',
          headers: {
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
          data: {
            title: this.title,
            target_name: this.target_name,
            target_id: this.target_id,
            price: this.price,
            selected_date: this.selected_date,
            shortTitle: this.shortTitle,
          },
        }).then(() => {
          this.title = null
          this.target_name = null
          this.shortTitle = null
          this.target_id = null
          this.selected_date = null
          this.price = null
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Sikeresen létrehozva',
              icon: 'CheckIcon',
              variant: 'success',
            },
          })
        }).catch(error => {
          return this.$toast({
            component: ToastificationContent,
            props: {
              title: Object.values(error.response.data.errors)[0][0],
              icon: 'XOctagonIcon',
              variant: 'danger',
            },
          })
        })
        return null
      }

      if (this.work_type === 'Nem projekttel kapcsolatos') {
        this.work_id = 'Nem projekttel kapcsolatos'
      }

      if (this.work_type === 'Saját feladat') {
        this.work_id = 'Saját feladat'
        this.selectedPreparatoryLeader = JSON.parse(localStorage.getItem('user')).id
      }

      if (this.work_type === 'Projekthez kapcsolódik') {
        this.work_id = this.work_number
      }
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Kérés elküldve feldolgozásra',
          icon: 'LoaderIcon',
          variant: 'warning',
        },
      })

      const formData = new FormData()
      formData.append('title', this.project_name)
      formData.append('project_id', 0)
      formData.append('work_id', this.work_id)
      formData.append('description', this.description)
      formData.append('start_date', this.startDate)
      formData.append('end_date', this.endDate)
      formData.append('priority', this.priority)
      formData.append('customer', this.customer)
      formData.append('target_id', this.selectedPreparatoryLeader)
      formData.append('selected', this.selected)
      formData.append('email', this.email)
      formData.append('work_number', this.work_number)
      formData.append('company', this.company)
      console.log(this.file_upload)
      if (this.file_upload !== null) {
        this.file_upload.forEach(file => {
          formData.append('file_upload[]', file)
        })
      }
      axios({
        method: 'post',
        url: 'project/task/create',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'Content-Type': 'multipart/form-data',
        },
        data: formData,
      }).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Sikeresen létrehozva',
            icon: 'CheckIcon',
            variant: 'success',
          },
        })
        this.$router.push({ name: 'monolit.global.todo' })
      }).catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: Object.values(error.response.data.errors)[0][0],
            icon: 'XOctagonIcon',
            variant: 'danger',
          },
        })
      })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style scoped>
  .flatpickr-input{
    display: none !important;
  }
</style>

<style>
  .vue-form-wizard .wizard-card-footer .wizard-footer-right .wizard-btn::after{
    content: "►" !important;
  }
  .vue-form-wizard .wizard-card-footer .wizard-footer-left .wizard-btn::before{
    content: "◄" !important;
  }
  .autosuggest__results-container{
    background: var(--primary);
    position: absolute;
    width: 100%;
    z-index: 1;
  }
  .autosuggest__results-item{
    list-style: square;
    margin-top: 3px;
  }
</style>
